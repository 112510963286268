import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Clear,
  Switch,
  Card,
  Map,
  Filters,
  FullScreenDialog,
} from "../../components/index";
import { colorPrimary } from "../../theme/index";
import apiAxios from "../../api/axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLocation } from "react-router-dom";
import Mutex from "../../utils/mutex";
import { save, getFavoritesCodes, deleteItem } from "../../utils/local-storage";

const mutext = new Mutex();

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  contendMap: (props) => ({
    width: "100%",
    minWidth: props.md && 500,
    maxWidth: props.md && 500,
    maxHeight: props.md ? 700 : "100%",
    position: "relative",
    margin: 10,
  }),
  contendProperties: (props) => ({
    display: "grid",
    gridTemplateColumns:
      props.md && !props.viewMap
        ? "repeat(4, 1fr)"
        : props.sm
        ? "repeat(3, 1fr)"
        : "repeat(1, 1fr)",
    gridGap: theme.spacing(2),
    gridAutoRows: "minmax(100px, auto)",
  }),
  contendProperties1: (props) => ({
    height: props.md && props.viewMap ? "calc(100vh - 80px)" : "100%",
    overflowY: props.md && props.viewMap ? "scroll" : "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  }),
  contendFilters: {
    display: "flex",
    height: 80,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingRight: 5,
  },
  contendBody: {
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    marginLeft: 10,
    marginRight: 10,
  },
  contenChexBoxGroup: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    height: "100%",
    width: "100%",
    padding: 10,
    boxShadow: "0px 0px 32px -9px rgba(0,0,0,0.75)",
    borderRadius: "10px",
  },
}));

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function Rent(props) {
  const Axios = new apiAxios();
  const md = useMediaQuery("(min-width:1030px)");
  const sm = useMediaQuery("(min-width:600px)");
  const [properties, setProperties] = useState([]);
  const [filters, setFilters] = useState({});
  const [filterActive, setFilterActive] = useState(false);
  const [viewMap, setViewMap] = useState(md ? true : false);
  const [markerMap, setMarkerMap] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [locationsCities, setLocationsCities] = useState([]);
  const classes = useStyles({ viewMap, md, sm });
  const location = useLocation();
  const [favoritesCodes, setFavoritesCodes] = useState([]);

  const [optionsBathroom] = useState([
    { value: 1, description: "1" },
    { value: 2, description: "2" },
    { value: 3, description: "3" },
    { value: 4, description: "4" },
  ]);
  const [optionsBedroom] = useState([
    { value: 1, description: "1" },
    { value: 2, description: "2" },
    { value: 3, description: "3" },
    { value: 4, description: "4" },
    { value: 5, description: "5" },
  ]);

  const [optionsPriceRent] = useState([
    { value: 1000000, description: "$1,000,000" },
    { value: 2000000, description: "$2,000,000" },
    { value: 3000000, description: "$3,000,000" },
    { value: 4000000, description: "$4,000,000" },
    { value: 5000000, description: "$5,000,000" },
    { value: 6000000, description: "$6,000,000" },
    { value: 7000000, description: "$7,000,000" },
    { value: 8000000, description: "$8,000,000" },
    { value: 9000000, description: "$9,000,000" },
    { value: 10000000, description: "$10,000,000" },
    { value: 11000000, description: "$11,000,000" },
    { value: 12000000, description: "$12,000,000" },
    { value: 13000000, description: "$13,000,000" },
    { value: 14000000, description: "$14,000,000" },
    { value: 15000000, description: "$15,000,000" },
  ]);

  const [optionsPriceSale] = useState([
    { value: 100000000, description: "$100,000,000" },
    { value: 200000000, description: "$200,000,000" },
    { value: 300000000, description: "$300,000,000" },
    { value: 400000000, description: "$400,000,000" },
    { value: 500000000, description: "$500,000,000" },
    { value: 600000000, description: "$600,000,000" },
    { value: 700000000, description: "$700,000,000" },
    { value: 800000000, description: "$800,000,000" },
    { value: 900000000, description: "$900,000,000" },
    { value: 1000000000, description: "$1000,000,000" },
    { value: 1100000000, description: "$1100,000,000" },
    { value: 1200000000, description: "$1200,000,000" },
    { value: 1300000000, description: "$1300,000,000" },
    { value: 1400000000, description: "$1400,000,000" },
    { value: 1500000000, description: "$1500,000,000" },
  ]);

  const activeFilters = () => {
    if (
      (filters.minBathrooms && filters.minBathrooms != "null") ||
      filters.maxBathrooms != "null" ||
      filters.minBedrooms != "null" ||
      filters.maxBedrooms != "null" ||
      filters.cityId != null ||
      filters.minPrice != "null" ||
      filters.maxPrice != "null"
    ) {
      setFilterActive(true);
    } else {
      setFilterActive(false);
    }
  };

  const handleChange = async (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const getPropeties = async (filters) => {
    await mutext.dispatch(async () => {
      if (!filters.unitTypes?.length) {
        delete filters.unitTypes;
      }

      return await Axios.getProperties({
        // unitCategories: props.category_id,
        ...filters,
      })
        .then(
          (it) => (
            console.log(it),
            setProperties(
              it.data.units.filter(
                (it) =>
                  it.unitCategory > 0 &&
                  // it.price > 0 &&
                  (it.unitCategory == props.category_id || it.unitCategory == 3)
              )
            ),
            setMarkerMap(
              it.data.units.map((it) => ({
                name: it.name,
                lat: it.latitude,
                lng: it.longitude,
                title: it.name,
                code: it.code,
                img: it.mainImageURL,
                address: it.address,
                city: it.city,
                price: it.price,
                bedroom: it.bedroom,
                bathrooms: it.bathrooms,
                area: it.area,
                unitCategoryDescription: it.unitCategoryDescription,
                totalMonthlyFee: it.totalMonthlyFee,
              }))
            )
          )
        )
        .catch((it) => console.log("error al obtener las propieades"));
    });
  };

  const setFavorite = (index, props) => {
    save(index, { ...props.property });
  };

  const deleteFavorite = (index, code) => {
    deleteItem(index, code);
  };

  const getUnitsTypes = () => {
    return Axios.getUnitTypes().then((it) =>
      setUnitTypes(it.data.types.filter((it) => it.code != 0))
    );
  };

  const getLocations = () => {
    return Axios.getLocations().then((it) =>
      setLocationsCities(it.data.cities)
    );
  };

  const loadData = async () => {
    Promise.all([getUnitsTypes(), getLocations()]);
  };

  const resetFilters = async () => {
    setFilters({
      minBathrooms: "null",
      maxBathrooms: "null",
      minBedrooms: "null",
      maxBedrooms: "null",
      cityId: null,
      minPrice: "null",
      maxPrice: "null",
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams);

    setFilters(params);
  }, []);

  useEffect(
    debounce(() => {
      getFavoritesCodes("favorites").then((it) => setFavoritesCodes(it));
    }, 250),
    [favoritesCodes]
  );

  useEffect((props) => {
    loadData();
    resetFilters();
  }, []);

  useEffect(() => {
    getPropeties(filters);
    activeFilters();
  }, [filters]);

  return (
    <div className={classes.container}>
      <div className={classes.contendFilters}>
        {md ? (
          <Filters
            filters={filters}
            handleChange={handleChange}
            locationsCities={locationsCities}
            unitTypes={unitTypes}
            optionsBedroom={optionsBedroom}
            optionsBathroom={optionsBathroom}
            optionsPrice={
              props.category_id == 1 ? optionsPriceRent : optionsPriceSale
            }
          />
        ) : (
          <FullScreenDialog
            filterActive={filterActive}
            resetFilters={resetFilters}
            children={
              <Filters
                filters={filters}
                handleChange={handleChange}
                locationsCities={locationsCities}
                unitTypes={unitTypes}
                optionsBedroom={optionsBedroom}
                optionsBathroom={optionsBathroom}
                optionsPrice={
                  props.category_id == 1 ? optionsPriceRent : optionsPriceSale
                }
              />
            }
          />
        )}
        <div className={classes.divider}>
          <Divider size={30} width={1} backgroundColor={colorPrimary} />
        </div>
        <div className={classes.divider}>
          <Clear
            size={30}
            width={1}
            backgroundColor={colorPrimary}
            onClick={resetFilters}
          />
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Switch
            size={30}
            width={1}
            backgroundColor={colorPrimary}
            handleChange={() => setViewMap(viewMap ? false : true)}
            checked={viewMap}
          />
        </div>
      </div>
      <div className={classes.contendBody}>
        {!md && viewMap ? (
          <div
            className={classes.contendMap}
            style={{
              height: "600px",
              width: "100%",
              minWidth: "100px",
              margin: "auto",
            }}
          >
            <Map
              markerMap={markerMap}
              category_id={props.category_id}
              modalMap={true}
            />
          </div>
        ) : (
          <div className={classes.contendProperties1}>
            <div className={classes.contendProperties}>
              {properties.length > 0 &&
                properties.map((it) => (
                  <Card
                    deleteItemFavorite={deleteFavorite}
                    saveFavorite={setFavorite}
                    favorite={favoritesCodes.includes(it.code)}
                    key={it.code}
                    code={it.code}
                    property={it}
                    category_id={props.category_id}
                  ></Card>
                ))}
              {properties.length == 0 && (
                <div>
                  <div>No se encontrarón resultados</div>
                  <div onClick={resetFilters} style={{ cursor: "pointer" }}>
                    Atrás
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {md && viewMap && (
          <div className={classes.contendMap}>
            <Map
              markerMap={markerMap}
              category_id={props.category_id}
              modalMap={true}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Rent;
