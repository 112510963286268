import React from "react";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const FavoriteIconOutLined = (props) => {
  const classes = useStyles(props);

  return <FavoriteBorderOutlinedIcon />;
};
export default FavoriteIconOutLined;
