import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colorSecundary } from "../../theme/index";
import { Map, Carrusel } from "../../components/index";
import { ContactForm } from "../../components/forms/index";
import Axios from "../../api/axios";
import Viewer from "../../components/viewer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  ListAmenities,
  FirstSectionDesktop,
  FirstSectionMovile,
} from "./components";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  contendSectionOne: (props) => ({
    backgroundColor: "#EFF4FF",
    paddingTop: props.sm ? 30 : 0,
    paddingBottom: 30,
    borderBottom: "1.7px #D2E4F9 solid",
  }),
  contendData: (props) => ({
    display: props.sm ? "flex" : "contents",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    margin: "0 auto",
    maxWidth: 1200,
  }),
  features: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  listFeaturesTitle: {
    marginTop: 24,
    fontWeight: "bold",
    fontSize: 20,
    color: colorSecundary,
  },
  contendListFeatures: {
    display: "flex",
    justifyContent: "space-between",
    color: "#5C6866",
    marginTop: 10,
    fontSize: 14,
    flexWrap: "wrap",
  },
  location: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  map: (props) => ({
    height: props.features ? (props.features.length ? 500 : 600) : 500,
    width: props.features ? (props.features.length ? "100%" : "100%") : "100%",
    position: "relative",
  }),
  contendSlider: {
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    height: 400,
    width: "100%",
    marginBottom: 300,
  },
  carrusel: {
    maxWidth: 1400,
  },
  contendDescription: {
    fontWeight: "bold",
    fontSize: 24,
    "& > p": {
      lineHeight: "25px",
    },
    textAlign: "left",
  },
  description: (props) => ({
    marginRight: !props.sm ? 0 : 10,
    fontSize: 16,
    fontWeight: "normal",
    marginTop: 10,
    textAlign: "justify",
  }),
}));

export default function PropertyDetails(props) {
  const axios = new Axios();

  const [property, setProperty] = useState({ images: [] });
  const [properties, setProperties] = useState([]);
  const [viewer, setViewer] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [markerMap, setMarkerMap] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const md = useMediaQuery("(min-width:1030px)");
  const sm = useMediaQuery("(min-width:600px)");
  const location = useLocation();

  const setData = (params) => {
    Promise.all([
      axios.getPropertyDetails(params.code).then((it) => {
        console.log(it.data.units[0]);
        setProperty(it.data.units[0]);
        setMarkerMap(
          it.data.units.map((it) => ({
            lat: it.latitude,
            lng: it.longitude,
          }))
        );
      }),
      axios
        .getProperties({
          // unitCategories: params.category_id,
        })
        .then((it) => {
          setProperties(
            it.data.units.filter(
              (it) =>
                it.unitCategory > 0 &&
                it.price > 0 &&
                (it.unitCategory == params.category_id || it.unitCategory == 3)
            )
          );
        })
        .catch((it) => console.log("error al obtener las propieades")),
    ]);
  };

  useEffect(async (props) => {
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams);
    setCategoryId(params.category_id);
    setData(params);
  }, []);

  const closeViewer = () => {
    setViewer(false);
  };

  const classes = useStyles({ property, md, sm });

  return (
    <div className={classes.container}>
      <div className={classes.contendSectionOne}>
        <div className={classes.contendData}>
          {sm ? (
            <FirstSectionDesktop
              setViewer={setViewer}
              setActiveIndex={setActiveIndex}
              category_id={props.category_id}
              property={property}
              closeViewer={closeViewer}
            />
          ) : (
            <FirstSectionMovile
              setViewer={setViewer}
              closeViewer={closeViewer}
              property={property}
              category_id={props.category_id}
              setActiveIndex={setActiveIndex}
            />
          )}
        </div>
      </div>
      <div className={classes.features}>
        <div
          className={classes.contendData}
          style={{
            marginTop: 30,
            flexWrap: sm ? "nowrap" : "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              textAlign: "left",
              flexDirection: "column",
              width: sm ? "auto" : "100%",
              fontWeight: "bold",
              flexGrow: 1,
            }}
          >
            {property.features && !!property.features.length ? (
              <div style={{ maxWidth: 700 }}>
                {property.description && (
                  <div className={classes.description}>
                    {property.description}
                  </div>
                )}
                <div className={classes.listFeaturesTitle}>
                  <ListAmenities
                    label="Caracteristicas"
                    data={property.features}
                  />
                </div>
              </div>
            ) : (
              <div style={{ width: sm ? "90%" : "100%" }}>
                {property.description && (
                  <div className={classes.description}>
                    {property.description}
                  </div>
                )}
                <div
                  className={classes.contendDescription}
                  style={{
                    marginTop: 0,
                    marginBottom: 10,
                    textAlign: "center",
                  }}
                >
                  Ubicación
                </div>
                <div className={classes.map}>
                  <Map unit={false} markerMap={markerMap} modal={false} />
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              // width: "100%",
              display: "flex",
              justifyContent: sm ? "flex-end" : "center",
            }}
          >
            <ContactForm
              projectName={`${property.name}`}
              projectId={property.code}
              moduleId={property.moduleId}
            />
          </div>
        </div>
      </div>
      {property.features && !!property.features.length && (
        <div style={{ width: sm ? "100%" : "100%" }}>
          <div className={classes.location}>
            <div
              className={classes.contendDescription}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              Ubicación
            </div>
            <div
              className={classes.map}
              style={{ maxWidth: sm ? "1200px" : "100%" }}
            >
              <Map unit={false} markerMap={markerMap} modal={false} />
            </div>
          </div>
        </div>
      )}
      <div className={classes.contendSlider}>
        <div
          className={classes.contendDescription}
          style={{ marginTop: 40, textAlign: "center", marginBottom: 30 }}
        >
          Otros apartamentos que pueden interesarte
        </div>
        <div
          style={{
            maxWidth: sm ? "1200px" : "100%",
            margin: "0 auto",
          }}
        >
          <Carrusel
            properties={properties}
            arrows={sm ? true : false}
            cards
            category_id={categoryId}
          />
        </div>
      </div>
      <Viewer
        visible={viewer}
        activeIndex={activeIndex}
        onClose={() => setViewer(false)}
        images={property.images.map((it) => ({
          src: it.source,
          alt: it.title,
        }))}
      />
    </div>
  );
}
