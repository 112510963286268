import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, Typography, Box } from "@material-ui/core/";
import numeral from "numeral";
import {
  colorSecundary,
  textColorPrimary,
  textColorSecondary,
} from "../../theme/index";
import {
  BedIcon,
  ShapeIcon,
  BathroomIcom,
  FavoriteIconOutLined,
  FavoriteIcon,
} from "../icons/index";

const useStyles = makeStyles({
  root: (props) => ({
    // maxWidth: 250,
    cursor: "pointer",
  }),
  price: {
    color: textColorPrimary,
    fontSize: "22px",
    fontWeight: "bold",
  },
  address: {
    color: textColorSecondary,
    fontSize: "16px",
    marginTop: 6,
    marginBottom: 6,
  },
  city: {
    color: colorSecundary,
  },
  features: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: textColorSecondary,
    marginTop: 12,
    "& > div": {
      display: "flex",
    },
  },
  icon: {
    marginRight: 5,
  },
  description: {
    marginTop: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
    "& > :first-child": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  unitCategoryDescription: {
    border: "2px solid #5FFF33",
    padding: 2,
    paddingLeft: 8,
    paddingRight: 8,
    color: "#5FFF33",
    fontWeight: "bold",
    borderRadius: 10,
  },
  image: {
    transition: "transform .3s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
});

export default function CardProperty(props) {
  const classes = useStyles();

  useEffect(async (props) => {}, [props]);

  return (
    <div className={classes.root}>
      <Box
        bgcolor="background.paper"
        m={0}
        p={0}
        style={{
          borderRadius: 10,
          overflow: "hidden",
          // boxShadow: "4px -1px 3px 1px teal",
          // -moz-box-shadow: 0px 6px 14px -6px rgba(211,220,230,1);
          boxShadow: "0px 6px 14px -4px rgba(211,220,230,1)",
        }}
      >
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              right: 20,
              top: 10,
              color: "white",
            }}
            onClick={() =>
              props.favorite
                ? props.deleteItemFavorite("favorites", props.code)
                : props.saveFavorite("favorites", props)
            }
          >
            <div>
              {props.favorite ? <FavoriteIcon /> : <FavoriteIconOutLined />}
            </div>
          </div>
        </div>
        <div
          onClick={() =>
            (window.location = `/detalle/?code=${props.code}&category_id=${props.category_id}`)
          }
        >
          <div className={classes.image}>
            <img
              src={`${
                props.property.mainImageURL
                  ? props.property.mainImageURL
                  : "https://bienvivir.com.co/wp-content/uploads/2021/02/withoutImage.jpg"
              }`}
              style={{
                objectPosition: "center",
                objectFit: "cover",
                height: "260px",
                width: "100%",
              }}
            />
          </div>
          <div className={classes.description}>
            <div>
              {props.hiddenPrice ? (
                <></>
              ) : (
                <Typography
                  variant="body2"
                  className={classes.price}
                  component="p"
                >
                  $
                  {numeral(
                    props.category_id == 1
                      ? props.property.totalMonthlyFee
                      : props.category_id == 2
                      ? props.property.price
                      : props.category_id == 3 && props.property.price
                  ).format("0,0")}
                </Typography>
              )}
              <Typography
                variant="body2"
                component="p"
                className={classes.unitCategoryDescription}
              >
                {props.property.unitCategoryDescription}
              </Typography>
            </div>
            <Typography
              variant="body2"
              className={classes.address}
              component="p"
              style={{ fontFamily: "send-bold" }}
            >
              {props.property.neighborhood ? (
                props.property.neighborhood
              ) : props.property.zone ? (
                props.property.zone
              ) : (
                <>&nbsp;</>
              )}
            </Typography>
            <Typography variant="body2" className={classes.city} component="p">
              {props.property.city}
            </Typography>
            <div className={classes.features}>
              <div>
                <div className={classes.icon}>
                  <BedIcon />
                </div>
                <label>{props.property.bedroom} hab.</label>
              </div>
              <div>
                <div className={classes.icon}>
                  <BathroomIcom />
                </div>
                <label>{props.property.bathrooms} bañ.</label>
              </div>
              <div>
                <div className={classes.icon}>
                  <ShapeIcon />
                </div>
                <label style={{ marginTop: -4 }}>
                  {props.property.area} m<sup>2</sup>
                </label>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
