import React from "react";
import CallIcon from "@material-ui/icons/Call";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const CallIconMe = (props) => {
  const classes = useStyles(props);

  return <CallIcon />;
};
export default CallIconMe;
