import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { nanoid } from "nanoid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: (props) => ({
    padding: 5,
    margin: 5,
    border: props.value ? "2px solid #0D3B92" : "2px solid #C0CCDB",
    borderRadius: "7px",
    color: props.value ? "#0D3B92" : "#C0CCDB",
  }),
}));

export default function NativeSelects(props) {
  const classes = useStyles(props);
  // const [id] = useState(nanoid);
  useEffect((props) => {}, []);

  return (
    <div>
      <select
        key={props.key}
        name={props.name}
        className={classes.root}
        onChange={props.handleChange}
      >
        <option key={1} value={null} selected>
          {props.label}
        </option>
        {props.options.map((it) => (
          <option key={it.id} value={it.id}>
            {it.name}
          </option>
        ))}
      </select>
    </div>
  );
}
