import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const BedIcon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.004"
      height="17.008"
      viewBox="0 0 17.004 17.008"
    >
      <g id="shape" transform="translate(-0.06 0)">
        <g id="Grupo_78" data-name="Grupo 78" transform="translate(0.06 0)">
          <g id="Grupo_77" data-name="Grupo 77" transform="translate(0 0)">
            <path
              id="Trazado_19"
              data-name="Trazado 19"
              d="M17.064,4.253A2.125,2.125,0,0,0,13,3.393l-1.815-.3-.177,1.048,1.824.3a2.125,2.125,0,0,0,1.311,1.777l-.518,3.9a2.121,2.121,0,0,0-1.874,2.1v.008L5.625,13.847a2.115,2.115,0,0,0-1.572-1.063L2.968,4.1A2.131,2.131,0,0,0,4.126,2.988l1.642.274.175-1.048-1.65-.276a2.12,2.12,0,1,0-2.38,2.285L3,12.906A2.125,2.125,0,1,0,5.906,14.88v-.008l6.126-1.615a2.12,2.12,0,1,0,2.644-3l.518-3.9A2.121,2.121,0,0,0,17.064,4.253ZM2.187,3.191A1.063,1.063,0,1,1,3.249,2.128,1.063,1.063,0,0,1,2.187,3.191ZM3.781,15.943A1.063,1.063,0,1,1,4.843,14.88,1.063,1.063,0,0,1,3.781,15.943Zm11.158-3.719a1.063,1.063,0,1,1-1.063-1.063A1.063,1.063,0,0,1,14.939,12.224Zm-1.063-7.97a1.063,1.063,0,1,1,1.063,1.063A1.063,1.063,0,0,1,13.876,4.253Z"
              transform="translate(-0.06 0)"
              fill="#3b4144"
            />
            <rect
              id="Rectángulo_51"
              data-name="Rectángulo 51"
              width="1.063"
              height="1.063"
              transform="matrix(0.986, 0.165, -0.165, 0.986, 9.028, 2.74)"
              fill="#3b4144"
            />
            <rect
              id="Rectángulo_52"
              data-name="Rectángulo 52"
              width="1.063"
              height="1.063"
              transform="translate(6.928 2.389) rotate(9.486)"
              fill="#3b4144"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default BedIcon;
