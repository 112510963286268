import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select } from "antd";
const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    "& .ant-select-selector, & .ant-select-selector input": {
      height: "100% !important",
      alignItems: "center !important",
    },
  },
}));

export default (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Select
        showSearch
        style={{ width: "100%", height: "100%", fontSize: 16 }}
        placeholder="Ciudad"
        optionFilterProp="children"
        onChange={(value) => props.handleChange("cityId", value)}
        icon={false}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {props.options.map((it) => (
          <Option value={it.cityId}>{it.name}</Option>
        ))}
      </Select>
    </div>
  );
};
