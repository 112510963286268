import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { colorSecundary } from "../../theme/index";

const useStyles = makeStyles({
  root: { display: "flex", alignItems: "center" },
  switch: (props) => ({
    "& .slider": {
      position: "absolute",
      cursor: "pointer",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#ccc",
      transition: ".4s",
      "&:before": {
        position: "absolute",
        content: '""',
        height: "26px",
        width: "26px",
        left: "4px",
        bottom: "4px",
        backgroundColor: "white",
        transition: ".4s",
      },
      "&.round": {
        borderRadius: "34px",
      },
      "&.round:before": {
        borderRadius: "50%",
      },
    },
    position: "relative",
    display: "inline-block",
    width: "60px",
    height: "34px",
    margin: 5,
    "& input": {
      display: "none",
      "&:checked + .slider": {
        backgroundColor: colorSecundary,
      },
      "&:checked + .slider:before": {
        transform: "translateX(26px)",
      },
    },
  }),
});

export default function Clear(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <label>Ver Mapa</label>
      <label className={classes.switch}>
        <input
          type="checkbox"
          onChange={props.handleChange}
          checked={props.checked}
        />
        <div className={clsx("slider", "round")}></div>
      </label>
    </div>
  );
}
