import React from "react";
import "antd/dist/antd.css";
import { Checkbox } from "antd";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    margin: 5,
  },
});

export default function Divider(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Checkbox
        name={props.name}
        code={props.code}
        onChange={() => props.handleChange(props.name, props.code)}
        checked={props.checked}
      >
        {props.description}
      </Checkbox>
    </div>
  );
}
