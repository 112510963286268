import axios from "axios";
import { apiWordPress, companyCode, projectCode } from "../constant/index";

class Axios {
  constructor() {
    this.urlBase = apiWordPress;
    this.unitCategories = null;
  }

  async getProperties(params) {
    const searchParams = new URLSearchParams(params);

    return axios.get(
      `${
        this.urlBase
      }v1Inmobiliario/getUnitsByCriteria/${companyCode}/${projectCode}/?${searchParams.toString()}`
    );
  }

  async getUnitTypes() {
    return axios.get(
      `${this.urlBase}v1Inmobiliario/getUnitType/${companyCode}/${projectCode}`
    );
  }

  async getLocations() {
    return axios.get(
      `${this.urlBase}v1Inmobiliario/getUnitLocation/${companyCode}/${projectCode}`
    );
  }

  async getPropertyDetails(unitCode) {
    return axios.get(
      `${this.urlBase}v1Inmobiliario/getUnit/${companyCode}/${projectCode}/${unitCode}`
    );
  }

  async createContact(data) {
    return axios.post(`${this.urlBase}SmartInmobiliario`, data);
  }
}

export default Axios;
