import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const BedIcon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="17"
      viewBox="0 0 24 17"
    >
      <g id="bed" transform="translate(-0.011 -72.814)">
        <g id="Grupo_24" data-name="Grupo 24" transform="translate(0 72.806)">
          <path
            id="Trazado_14"
            data-name="Trazado 14"
            d="M22.011,79.814v-5c0-1.167-1.833-2-3-2h-15a1.925,1.925,0,0,0-2,2v5a2.966,2.966,0,0,0-2,3v4a1.313,1.313,0,0,0,1,1h1v1a1.313,1.313,0,0,0,1,1,1.313,1.313,0,0,0,1-1v-1h16v1a1.313,1.313,0,0,0,1,1,1.313,1.313,0,0,0,1-1v-1h1a1.313,1.313,0,0,0,1-1v-4A2.966,2.966,0,0,0,22.011,79.814Zm-18-5c0-.389-.389-1,0-1h15a1.316,1.316,0,0,1,1,1v5h-2v-1a1.858,1.858,0,0,0-2-2h-3c-.591,0-.6-.376-1,0-.4-.376-1.409,0-2,0h-3a1.858,1.858,0,0,0-2,2v1h-1Zm13,4v1h-4v-1c0-.453-.453-1,0-1h3A1.16,1.16,0,0,1,17.011,78.814Zm-6,0v1h-4v-1c0-.453-.453-1,0-1h3A1.16,1.16,0,0,1,11.011,78.814Zm11,8h-21v-4c0-.879,1.121-1,2-1h18c.879,0,1,.121,1,1Z"
            transform="translate(0 -72.806)"
            fill="#3b4144"
          />
        </g>
      </g>
    </svg>
  );
};
export default BedIcon;
