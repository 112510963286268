import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from "antd";

const useStyles = makeStyles({
  root: (props) => ({
    height: 50,
    marginBottom: 10,
    marginTop: 10,
    minWidth: 220,
    "&::placeholder": {
      color: "black",
    },
  }),
});

export default function InputTextArea(props) {
  const classes = useStyles(props);
  const { TextArea } = Input;

  return (
    <TextArea
      onChange={(e) => console.log(e)}
      placeholder={props.placeholder}
      name={props.name}
      value={props.value}
      className={classes.root}
      rows={props.rows}
    />
  );
}
