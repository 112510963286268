import React from "react";
import logo from "./logo.svg";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./redux/index";
import "./App.css";
import { useEffect } from "react";
import { testCreate } from "./redux/actions/test";
import { PropertyDetails, Rent, Home, Favorite } from "./pages/index";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const TestComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(testCreate());
  }, []);

  return <div></div>;
};

function App() {
  return (
    <Provider store={store()}>
      <Router>
        <TestComponent />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/favoritos">
            <Favorite />
          </Route>
          <Route exact path="/arrendar">
            <Rent category_id={1} />
          </Route>
          <Route exact path="/comprar">
            <Rent category_id={2} />
          </Route>
          <Switch>
            <Route exact path="/detalle" children={<PropertyDetails />} />
            {/* <Route
              exact
              path="/comprar/detalle-proyecto/"
              children={<PropertyDetails category_id={2} />}
            /> */}
          </Switch>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
