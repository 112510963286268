import React from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const BedIcon = (props) => {
  const classes = useStyles(props);

  return <WhatsAppIcon />;
};
export default BedIcon;
