import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "antd";
import { value } from "numeral";

const useStyles = makeStyles({
  root: (props) => ({
    height: 50,
    marginBottom: "10px !important",
    marginTop: "10px !important",
    minWidth: 220,
    "&::placeholder": {
      color: "black",
    },
  }),
});

export default function InputField(props) {
  const classes = useStyles(props);

  return <DatePicker {...props} className={classes.root} />;
}
