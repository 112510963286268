// Import Swiper React components
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BedIcon, ShapeIcon } from "../../../components/icons/";
import lodash from "lodash";
import numeral from "numeral";

const useStylesList = makeStyles((theme) => ({
  contendGalery: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    columnGap: "10px",
    rowGap: "10px",
  },
  contendImgMain: {
    gridRow: "1/3",
    gridColumn: "1/3",
    backgroundColor: "gray",
    borderRadius: 16,
    cursor: "pointer",
  },
  item: {
    position: "relative",
    borderRadius: 16,
    cursor: "pointer",
    "& .foreground": {
      position: "absolute",
      backgroundColor: "black",
      opacity: ".5",
      width: "100%",
      height: "100%",
      top: 0,
      borderRadius: 16,
    },
    "& .viewMore": {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      borderRadius: 16,
      color: "white",
      fontSize: 60,
      display: "flex",
      alignItems: "center",
    },
    "& .viewMoreButton": {
      border: "2px white solid",
      fontSize: 30,
      fontWeight: "bold",
      cursor: "pointer",
      margin: "0 auto",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  contendTitle: {
    gridColumn: "1/3",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: 30,
    marginBottom: 10,
  },
  contendDescription: {
    fontWeight: "bold",
    fontSize: 24,
    "& > p": {
      lineHeight: "25px",
    },
    textAlign: "left",
  },
  contendDescription1: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridColumn: "3/5",
    height: 100,
    marginTop: 30,

    "& > div": {
      border: "1px #5C6866 solid",
      "&:not(:first-child)": {
        borderLeft: "none",
      },
      "&:first-child": {
        borderRadius: "10px 0px 0px 10px",
      },
      "&:last-child": {
        borderRadius: "0px 10px 10px 0px",
      },
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  },
  contendTitle: {
    gridColumn: "1/3",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: 30,
    marginBottom: 10,
  },
  itemsFeatures: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    alignItems: "flex-start",
    fontWeight: "bold",
    fontSize: 16,
    color: "#4E5857",
  },
}));

const ItemsFeatures = (props) => (
  <div>
    <div>
      <label>{props.label}</label>
    </div>
    <div>
      {props.child}
      <label>
        &nbsp; {props.value}
        {props.sup}
      </label>
    </div>
  </div>
);

const Value = (props) => {
  return props.property.unitCategory == 1 ? (
    <label>
      {`${"Valor Arriendo:"} $${numeral(props.property.monthlyFee).format(
        "0,0"
      )}`}
    </label>
  ) : props.property.unitCategory == 2 ? (
    <label>
      {`${"Valor:"} $${numeral(props.property.price).format("0,0")}`}
    </label>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <label>
        {`${"Valor Arriendo:"} $${numeral(props.property.monthlyFee).format(
          "0,0"
        )}`}
      </label>
      <label>
        {`${"Valor Venta:"} $${numeral(props.property.price).format("0,0")}`}
      </label>
    </div>
  );
};

export default (props) => {
  const classes = useStylesList(props);
  return (
    <div className={classes.contendGalery}>
      <div className={classes.contendImgMain}>
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "410px",
            borderRadius: 16,
            objectPosition: "center",
            objectFit: "cover",
            width: "100%",
          }}
          src={`${
            props.property.mainImageURL
              ? props.property.mainImageURL
              : "https://bienvivir.com.co/wp-content/uploads/2021/02/withoutImage.jpg"
          }`}
          onClick={() => {
            props.property.mainImageURL && props.setViewer(true);
            props.setActiveIndex(0);
          }}
        />
      </div>

      {[1, 2, 3, 4].map((it, key) => (
        <div className={classes.item}>
          <img
            style={{
              borderRadius: 16,
              maxHeight: 200,
              objectPosition: "center",
              objectFit: "cover",
              width: "100%",
            }}
            src={`${
              props.property.images && props.property.images[it]?.source
                ? props.property.images[it]?.source
                : "https://bienvivir.com.co/wp-content/uploads/2021/02/withoutImage.jpg"
            }`}
            onClick={() => {
              props.property.images &&
                props.property.images[it]?.source &&
                props.setViewer(true);
              props.setActiveIndex(it);
            }}
          />
          {it == 4 && (
            <div
              onClick={() => {
                props.setViewer(true);
                props.setActiveIndex(4);
              }}
            >
              <div className="foreground"></div>
              <div className="viewMore">
                <div className="viewMoreButton">Ver más</div>
              </div>
            </div>
          )}
        </div>
      ))}
      <div className={classes.contendTitle}>
        <div className={classes.contendDescription}>
          <div className={classes.name}>{`${lodash.upperFirst(
            props.property.city?.toLowerCase()
          )} ${props.property.city && props.property.neighborhood && `- `}${
            props.property.neighborhood
          }`}</div>
        </div>
        <div style={{ fontSize: 16 }}>
          <Value property={props.property} />
        </div>
        <div style={{ fontSize: 16 }}>
          {`Valor Administración ${numeral(
            props.property.maintenanceFee
          ).format("0,0")}`}
        </div>
      </div>
      <div className={classes.contendDescription1}>
        <div className={classes.itemsFeatures}>
          <ItemsFeatures
            label="Área"
            value={props.property.area}
            child={<ShapeIcon />}
            sup={<sup>m2</sup>}
          />
        </div>
        <div className={classes.itemsFeatures}>
          <ItemsFeatures
            label="Habitaciones"
            value={props.property.bedroom}
            child={<BedIcon />}
          />
        </div>
        <div className={classes.itemsFeatures} style={{ textAlign: "center" }}>
          <ItemsFeatures label="Estrato" value={props.property.strata} />
        </div>
      </div>
    </div>
  );
};
