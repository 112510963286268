export default (function () {
  function Mutex() {
    this.mutex = Promise.resolve();
  }

  Mutex.prototype.lock = function () {
    let begin = (unlock) => {};

    this.mutex = this.mutex.then(() => new Promise(begin));

    return new Promise((res) => {
      begin = res;
    });
  };

  Mutex.prototype.dispatch = async function (fn) {
    const unlock = await this.lock();
    try {
      return Promise.resolve(await fn());
    } finally {
      unlock();
    }
  };

  return Mutex;
})();
