import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Clear } from "../index";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    color: "black",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  title: {
    marginRight: theme.spacing(2),
  },
  button: (props) => ({
    color: props.filterActive ? "white" : "black",
    borderColor: props.filterActive ? "blue" : "black",
    backgroundColor: props.filterActive ? "blue" : "white",
    borderWidth: 2,
  }),
  filters: {
    display: "flex",
    justifyContent: "row",
    textAlign: "start",
    "& > div": {
      flexDirection: "column",
      width: "100%",
      "& > div": {
        width: "100%",
      },
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  viewResult: {
    marginBottom: 10,
    marginRight: 10,
    marginLeft: 10,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        className={classes.button}
      >
        Filtros
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{ justifyContent: "space-between !important" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            <AppBar className={classes.appBar}>
              <Toolbar className={classes.toolbar}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Clear onClick={props.resetFilters} />
              </Toolbar>
            </AppBar>
            <div className={classes.filters}>{props.children}</div>
          </div>
          <div className={classes.viewResult}>
            <Button
              variant="contained"
              style={{
                background: "#114CBC",
                color: "white",
                width: "100%",
              }}
              onClick={handleClose}
            >
              Ver resultados
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
