import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

// reducers
import { test } from "./reducers/test";

// noinspection JSUnresolvedVariable
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function store() {
  return createStore(
    combineReducers({
      test,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
}

export default store;
