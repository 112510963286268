import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TimePicker } from "antd";

const useStyles = makeStyles({
  root: (props) => ({
    height: 50,
    marginBottom: "10px !important",
    marginTop: "10px !important",
    minWidth: 220,

    color: "red",
    "&::placeholder": {
      color: "red !important",
    },
  }),
});

export default function InputField(props) {
  const classes = useStyles(props);

  return (
    <TimePicker
      {...props}
      className={classes.root}
      format="HH:mm"
      minuteStep={30}
      use12Hours={true}
    />
  );
}
