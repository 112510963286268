import React from "react";
import Favorite from "@material-ui/icons/Favorite";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const FavoriteIconOutLined = (props) => {
  const classes = useStyles(props);

  return <Favorite />;
};
export default Favorite;
