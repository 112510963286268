import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SelectGroup, Dropdown } from "../index";
import { Radio, Checkbox, Collapse } from "antd";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles({
  root: (props) => ({
    display: "flex",
    "& div:not(:first-child)": {
      marginLeft: 10,
    },
  }),
  contenChexBoxGroup: (props, md) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    height: "100%",
    width: "100%",
    padding: 10,
    boxShadow: md ? "0px 0px 32px -9px rgba(0,0,0,0.75)" : "none",
    borderRadius: "10px",
  }),
  radioGroup: {
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
  },
  selected: {
    fontWeight: "bold",
    "& > div": {
      color: "#007bff !important",
    },
  },
  unseleted: {
    "& > div": {
      color: "black !important",
    },
  },
});

export default function Filters(props) {
  const md = useMediaQuery("(min-width:1030px)");
  const classes = useStyles(props, md);
  const { Panel } = Collapse;

  return (
    <div>
      {md ? (
        <div className={classes.root}>
          <div>
            <Dropdown
              children={
                <SelectGroup
                  options={props.optionsBathroom}
                  handleChange={props.handleChange}
                  min="minBathrooms"
                  max="maxBathrooms"
                  valueMin={props.filters.minBathrooms}
                  valueMax={props.filters.maxBathrooms}
                  boxShadow={true}
                />
              }
              focus={
                (props.filters.minBathrooms &&
                  props.filters.minBathrooms != "null") ||
                (props.filters.maxBathrooms &&
                  props.filters.maxBathrooms != "null")
                  ? true
                  : false
              }
              name="bathrooms"
              description="Baños"
            />
          </div>
          <div>
            <Dropdown
              children={
                <SelectGroup
                  options={props.optionsBedroom}
                  handleChange={props.handleChange}
                  min="minBedrooms"
                  max="maxBedrooms"
                  valueMin={props.filters.minBedrooms}
                  valueMax={props.filters.maxBedrooms}
                />
              }
              focus={
                (props.filters.minBedrooms &&
                  props.filters.minBedrooms != "null") ||
                (props.filters.maxBedrooms &&
                  props.filters.maxBedrooms != "null")
                  ? true
                  : false
              }
              name="bedroom"
              description="Habitaciones"
            />
          </div>
          <div>
            <Dropdown
              children={
                <div className={classes.contenChexBoxGroup}>
                  <Radio.Group
                    className={classes.radioGroup}
                    value={props.filters.cityId}
                    onChange={(value) =>
                      props.handleChange("cityId", value.target.value)
                    }
                  >
                    {props.locationsCities.map((it) => (
                      <div>
                        <Radio style={{ margin: 2 }} value={it.cityId}>
                          {it.name}
                        </Radio>
                      </div>
                    ))}
                  </Radio.Group>
                </div>
              }
              focus={props.filters.cityId ? true : false}
              name="city"
              description="Ciudades"
            />
          </div>
          <div>
            <Dropdown
              children={
                <div className={classes.contenChexBoxGroup}>
                  <Checkbox.Group
                    className={classes.radioGroup}
                    value={props.filters.unitTypes || []}
                    onChange={(value) => props.handleChange("unitTypes", value)}
                  >
                    {props.unitTypes.map((it) => (
                      <div>
                        <Checkbox value={it.code}>{it.name}</Checkbox>
                      </div>
                    ))}
                  </Checkbox.Group>
                </div>
              }
              focus={props.filters.unitTypes ? true : false}
              name="unitTypes"
              description="Tipo de propiedad"
            />
          </div>
          <div>
            <Dropdown
              children={
                <SelectGroup
                  options={props.optionsPrice}
                  handleChange={props.handleChange}
                  min="minPrice"
                  max="maxPrice"
                  valueMin={props.filters.minPrice}
                  valueMax={props.filters.maxPrice}
                />
              }
              focus={
                (props.filters.minPrice && props.filters.minPrice != "null") ||
                (props.filters.maxPrice && props.filters.maxPrice != "null")
                  ? true
                  : false
              }
              name="price"
              description="Precio"
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={classes.itemCollapse}>
            <div className={classes.items}>
              <Collapse>
                <Panel
                  header="Baños"
                  key="1"
                  // expandIconPosition="left"
                  className={
                    props.filters.minBathrooms != "null" ||
                    props.filters.maxBathrooms != "null"
                      ? classes.selected
                      : classes.unseleted
                  }
                  name="bathrooms"
                  description="Baños"
                >
                  <SelectGroup
                    options={props.optionsBathroom}
                    handleChange={props.handleChange}
                    min="minBathrooms"
                    max="maxBathrooms"
                    valueMin={props.filters.minBathrooms}
                    valueMax={props.filters.maxBathrooms}
                    boxShadow={false}
                  />
                </Panel>
              </Collapse>
            </div>
          </div>
          <div>
            <Collapse>
              <Panel
                className={
                  props.filters.minBedrooms != "null" ||
                  props.filters.maxBedrooms != "null"
                    ? classes.selected
                    : classes.unseleted
                }
                header="Habitaciones"
                key="2"
                expandIconPosition="left"
                name="bathrooms"
                description="Habitaciones"
              >
                <SelectGroup
                  options={props.optionsBedroom}
                  handleChange={props.handleChange}
                  min="minBedrooms"
                  max="maxBedrooms"
                  valueMin={props.filters.minBedrooms}
                  valueMax={props.filters.maxBedrooms}
                  boxShadow={false}
                />
              </Panel>
            </Collapse>
          </div>
          <div>
            <Collapse>
              <Panel
                className={
                  props.filters.cityId != null
                    ? classes.selected
                    : classes.unseleted
                }
                header="Ciudades"
                key="3"
                expandIconPosition="left"
                name="city"
                description="Ciudad"
              >
                <div className={classes.contenChexBoxGroup}>
                  <Radio.Group
                    className={classes.radioGroup}
                    value={props.filters.cityId}
                    onChange={(value) =>
                      props.handleChange("cityId", value.target.value)
                    }
                  >
                    {props.locationsCities.map((it) => (
                      <div>
                        <Radio style={{ margin: 2 }} value={it.cityId}>
                          {it.name}
                        </Radio>
                      </div>
                    ))}
                  </Radio.Group>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div>
            <Collapse>
              <Panel
                className={
                  props.filters.unitTypes != null
                    ? classes.selected
                    : classes.unseleted
                }
                header="Tipo de propiedad"
                key="4"
                expandIconPosition="left"
                name="unitTypes"
                description="Tipo de propiedad"
              >
                <div className={classes.contenChexBoxGroup}>
                  <Checkbox.Group
                    className={classes.radioGroup}
                    value={props.filters.unitTypes || []}
                    onChange={(value) => props.handleChange("unitTypes", value)}
                  >
                    {props.unitTypes.map((it) => (
                      <div>
                        <Checkbox value={it.code}>{it.name}</Checkbox>
                      </div>
                    ))}
                  </Checkbox.Group>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div>
            <Collapse>
              <Panel
                header="$Precio"
                key="5"
                expandIconPosition="left"
                name="price"
                description="$Precio"
              >
                <SelectGroup
                  options={props.optionsPrice}
                  handleChange={props.handleChange}
                  min="minPrice"
                  max="maxPrice"
                  valueMin={props.filters.minPrice}
                  valueMax={props.filters.maxPrice}
                />
              </Panel>
            </Collapse>
          </div>
        </div>
      )}
    </div>
  );
}
