// Import Swiper React components
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Carrusel } from "../../../components/index";
import { BedIcon, ShapeIcon } from "../../../components/icons/";
import numeral from "numeral";
import lodash from "lodash";

const ItemsFeatures = (props) => (
  <div>
    <div>
      <label>{props.label}</label>
    </div>
    <div>
      {props.child}
      <label>
        &nbsp; {props.value}
        {props.sup}
      </label>
    </div>
  </div>
);

const useStylesList = makeStyles((theme) => ({
  contendDescription: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  name: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  contendDescription1: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridColumn: "3/5",
    height: 100,
    marginTop: 30,
    width: "100%",
    "& > div": {
      border: "1px #5C6866 solid",
      "&:not(:first-child)": {
        borderLeft: "none",
      },
      "&:first-child": {
        borderRadius: "10px 0px 0px 10px",
      },
      "&:last-child": {
        borderRadius: "0px 10px 10px 0px",
      },
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  },
}));

const Value = (props) => {
  return props.property.unitCategory == 1 ? (
    <label>
      {`${"Valor Arriendo:"} $${numeral(props.property.monthlyFee).format(
        "0,0"
      )}`}
    </label>
  ) : props.property.unitCategory == 2 ? (
    <label>
      {`${"Valor:"} $${numeral(props.property.price).format("0,0")}`}
    </label>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <label>
        {`${"Valor Arriendo:"} $${numeral(props.property.monthlyFee).format(
          "0,0"
        )}`}
      </label>
      <label>
        {`${"Valor Venta:"} $${numeral(props.property.price).format("0,0")}`}
      </label>
    </div>
  );
};

export default (props) => {
  const md = useMediaQuery("(min-width:1030px)");
  const sm = useMediaQuery("(min-width:600px)");
  const classes = useStylesList(props);

  return (
    <div className={classes.root}>
      <div>
        <Carrusel
          images={props.property.images}
          arrows={sm ? true : false}
          setViewer={props.setViewer}
          closeViewer={props.closeViewer}
          setActiveIndex={props.setActiveIndex}
          dots
        />
      </div>
      <div className={classes.contendDescription}>
        <div className={classes.name}>{`${lodash.upperFirst(
          props.property.city?.toLowerCase()
        )} ${props.property.city && props.property.neighborhood && `- `}${
          props.property.neighborhood
        }`}</div>
        <div>
          <Value property={props.property} />
        </div>
        <div style={{ fontSize: 16 }}>
          {`Valor Administración ${numeral(
            props.property.maintenanceFee
          ).format("0,0")}`}
        </div>
        <div className={classes.contendDescription1}>
          <div className={classes.itemsFeatures}>
            <ItemsFeatures
              label="Área"
              value={props.property.area}
              child={<ShapeIcon />}
              sup={<sup>m2</sup>}
            />
          </div>
          <div className={classes.itemsFeatures}>
            <ItemsFeatures
              label="Habitaciones"
              value={props.property.bedroom}
              child={<BedIcon />}
            />
          </div>
          <div
            className={classes.itemsFeatures}
            style={{ textAlign: "center" }}
          >
            <ItemsFeatures label="Estrato" value={props.property.strata} />
          </div>
        </div>
      </div>
    </div>
  );
};
