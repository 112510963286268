import React from "react";
import "antd/dist/antd.css";
import { Button } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { colorSecundary } from "../../theme/index";

const useStyles = makeStyles({
  root: (props) => ({
    width: "100%",
    backgroundColor: props.disabled ? "gray" : colorSecundary,
    borderRadius: 10,
  }),
});

export default function ButtonMe(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <button
        type="submit"
        disabled={props.disabled}
        style={{
          backgroundColor: props.disabled ? "gray" : colorSecundary,
          color: "white",
          paddingBottom: 10,
          paddingTop: 10,
          height: 50,
          fontSize: 20,
          fontWeight: "bold",
          width: "100%",
        }}
      >
        {props.description}
      </button>
    </div>
  );
}
