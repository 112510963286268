import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const BedIcon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.369"
      height="18.277"
      viewBox="0 0 24.369 18.277"
    >
      <g id="bathtub" transform="translate(0 -64)">
        <g id="Grupo_76" data-name="Grupo 76" transform="translate(0 64)">
          <path
            id="Trazado_18"
            data-name="Trazado 18"
            d="M22.846,69.077h-.508V66.031a2.031,2.031,0,0,0-4.062,0v.509a.507.507,0,0,0,.507.509h0a.508.508,0,0,0,.508-.507v-.51a1.015,1.015,0,0,1,2.031,0v3.046H1.523a1.519,1.519,0,0,0-.508,2.953v1.617A6.6,6.6,0,0,0,4.062,79.2v2.571a.507.507,0,0,0,.508.508H5.585A.508.508,0,0,0,6.039,82l.893-1.786a6.646,6.646,0,0,0,.684.036h9.138a6.635,6.635,0,0,0,.684-.036L18.33,82a.508.508,0,0,0,.454.281H19.8a.507.507,0,0,0,.508-.508V79.2a6.6,6.6,0,0,0,3.046-5.553V72.029a1.519,1.519,0,0,0-.508-2.953ZM6.092,70.092h5.077v4.985l-5.077-.846ZM1.015,70.6a.508.508,0,0,1,.508-.508H5.077v1.015H1.523A.508.508,0,0,1,1.015,70.6ZM5.271,81.261H5.077V79.737a6.552,6.552,0,0,0,.819.274Zm14.021,0H19.1l-.625-1.25a6.559,6.559,0,0,0,.819-.274Zm3.046-7.615a5.591,5.591,0,0,1-5.585,5.585H7.615a5.591,5.591,0,0,1-5.585-5.585V72.123H5.077v2.538a.507.507,0,0,0,.424.5l6.092,1.015a.524.524,0,0,0,.083.007.508.508,0,0,0,.508-.508V72.123H22.338v1.523Zm.508-2.538H12.185V70.092H22.846a.508.508,0,1,1,0,1.015Z"
            transform="translate(0 -64)"
            fill="#3b4144"
          />
        </g>
      </g>
    </svg>
  );
};
export default BedIcon;
