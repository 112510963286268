import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "antd";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Dropdown, SelectGroup } from "../../components/";
import { SearchIcon } from "../../components/icons";
import apiAxios from "../../api/axios";
import { SelectWIthSearch } from "../../components/inputs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contendFilter: {
    borderRadius: 4,
    "& > button": {
      height: "100%",
    },
    maxWidth: 1200,
    width: 1200,
    height: "100%",
    border: "1px #D3DCE6 solid",
    alignItems: "strectch",
    "@media screen and (max-width: 600px)": {
      minWidth: "100%",
      height: 120,
    },
    height: 70,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  sectionOne: {
    minWidth: 650,
    "@media screen and (max-width: 600px)": {
      minWidth: "100%",
    },
  },
  button: {
    width: "100%",
    height: "100% !important",
  },
  buttonSearch: {
    width: 100,
    height: "100%",
  },
  menuItem: {
    "& > :hover": {
      backgroundColor: "#C8CAC7",
      width: "100%",
    },
  },
}));

function Home(props) {
  const classes = useStyles(props);
  const Axios = new apiAxios();
  const [filters, setFilters] = useState({ type: null });
  const [locationsCities, setLocationsCities] = useState([]);
  const [base, setBase] = useState("comprar");
  const md = useMediaQuery("(min-width:1030px)");
  const sm = useMediaQuery("(min-width:600px)");

  const handleChange = async (name, value) => {
    name == "type" && setBase(value == 1 ? "arrendar" : "comprar");
    setFilters({ ...filters, [name]: value });
  };

  const getLocations = () => {
    return Axios.getLocations().then((it) =>
      setLocationsCities(it.data.cities)
    );
  };

  const loadData = async () => {
    Promise.all([getLocations()]);
  };

  const [optionsBedroom] = useState([
    { value: 1, description: "1" },
    { value: 2, description: "2" },
    { value: 3, description: "3" },
    { value: 4, description: "4" },
    { value: 5, description: "5" },
  ]);

  const search = () => {
    delete filters.type;
    const searchParams = new URLSearchParams(filters);

    window.location.href = `${base}?${searchParams.toString()}`;
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contendFilter}>
        <div
          className={classes.sectionOne}
          style={{ display: "flex", minWidth: md ? 650 : "100%" }}
        >
          <Dropdown
            className={classes.button}
            home
            children={
              <Menu className={classes.menuItem}>
                <Menu.Item key={1}>
                  <div onClick={() => handleChange("type", 2)}>Comprar</div>
                </Menu.Item>
                <Menu.Item key={2}>
                  <div onClick={() => handleChange("type", 1)}>Arrendar</div>
                </Menu.Item>
              </Menu>
            }
            description={
              !filters.type
                ? "Arrendar - Comprar"
                : filters.type == 1
                ? "Arrendar"
                : "Comprar"
            }
          />
          <Dropdown
            home
            children={
              <SelectGroup
                options={optionsBedroom}
                handleChange={handleChange}
                min="minBedrooms"
                max="maxBedrooms"
              />
            }
            name="bedroom"
            description={"Habitaciones"}
          />
        </div>
        <div style={{ flexGrow: 1, display: "flex" }}>
          <SelectWIthSearch
            options={locationsCities}
            handleChange={handleChange}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: 100,
              justifyContent: "center",
              backgroundColor: "#0D3B92",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => search()}
          >
            <SearchIcon style={{ height: "26px !important" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
