// Import Swiper React components
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "../../../components/index";
import { colorSecundary } from "../../../theme/index";

const useStylesList = makeStyles((theme) => ({
  contendListFeatures: {
    display: "flex",
    justifyContent: "space-between",
    color: "#5C6866",
    marginTop: 10,
    fontSize: 14,
    flexWrap: "wrap",
  },
}));

export default (props) => {
  const classes = useStylesList(props);
  return (
    <div>
      <div>
        <label>{props.label}</label>
      </div>
      <div>
        <Divider size={2} width={160} backgroundColor={colorSecundary} />
      </div>
      <div className={classes.contendListFeatures}>
        {props.data &&
          props.data.map((it) => (
            <div style={{ minWidth: 180 }}>
              <span
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: colorSecundary,
                  marginRight: 5,
                }}
              >
                .
              </span>
              {it.name}
            </div>
          ))}
      </div>
    </div>
  );
};
