import React, { useEffect, useState } from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import "antd/dist/antd.css";
import { Select } from "antd";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    justifyContent: "row",
    backgroundColor: "white",
    alignItems: "center",
    height: 70,
    width: "100%",
    padding: 10,
    boxShadow: props.boxShadow ? "0px 0px 32px -9px rgba(0,0,0,0.75)" : "none",
    borderRadius: "10px",
  }),
  select: {
    width: "100%",
    minWidth: 86,
    border: "none !important",
    outline: "none !important",
    "& > option": { fontSize: 20 },
  },
}));

export default function SelectGroup(props) {
  const classes = useStyles(props);
  const { Option } = Select;

  useEffect((props) => {}, []);

  return (
    <div className={classes.root}>
      <div className={"select-css"}>
        <select
          name={props.min}
          // defaultValue={null}
          className={classes.select}
          value={props.valueMin}
          onChange={(e) => props.handleChange(props.min, e.target.value)}
        >
          <option key={43} value={"null"}>
            Sin Min
          </option>
          {props.options.map((it) => (
            <option key={it.value} value={it.value}>
              {it.description}
            </option>
          ))}
        </select>
      </div>
      <div style={{ margin: 6 }}>-</div>
      <div className={"select-css"}>
        <select
          className={classes.select}
          name={props.max}
          // defaultValue={null}
          style={{ width: "100%", minWidth: 86, border: "none" }}
          onChange={(e) => props.handleChange(props.max, e.target.value)}
          value={props.valueMax}
        >
          <option key={45} value={"null"}>
            Sin Máx
          </option>
          {props.options.map((it) => (
            <option
              key={it.value}
              disabled={props.valueMin > it.value ? true : false}
              value={it.value}
            >
              {it.description}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
