const save = async (index, data) => {
  let _data = {
    ...JSON.parse(localStorage.getItem(index) || "{}"),
    [data.code]: data,
  };

  localStorage.setItem(index, JSON.stringify(_data));

  return _data;
};

const getFavoritesCodes = async (index) => {
  const properties = await localStorage.getItem(index);
  let codes = [];
  if (properties) codes = Object.keys(JSON.parse(properties));
  return codes;
};

const deleteItem = async (index, code) => {
  const pro = JSON.parse(localStorage.getItem(index) || "{}");
  delete pro[code];
  localStorage.setItem(index, JSON.stringify(pro));
};

const getFavorites = async (index) => {
  const favorites = await localStorage.getItem(index);
  return favorites;
};

export { save, deleteItem, getFavoritesCodes, getFavorites };
