import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

const INITIAL_STATE = {
  show: {},
};

const { Types, Creators } = createActions({
  setTest: ["show"],
});

const setShow = (state, { show }) => {
  return produce(state, (draftState) => {
    draftState.show = show;
  });
};

// noinspection JSCheckFunctionSignatures
export const test = createReducer(INITIAL_STATE, {
  [Types.SET_TEST]: setShow,
});

export default {
  Types,
  Creators,
};
