import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const BedIcon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      id="loupe"
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 34.565 34.564"
    >
      <g id="Grupo_2" data-name="Grupo 2" transform="translate(0 0)">
        <path
          id="Trazado_4"
          data-name="Trazado 4"
          d="M34.142,32.109,24.313,22.28a13.708,13.708,0,1,0-2.036,2.036l9.829,9.829a1.44,1.44,0,1,0,2.036-2.036ZM13.682,24.486a10.8,10.8,0,1,1,10.8-10.8A10.813,10.813,0,0,1,13.682,24.486Z"
          transform="translate(0 -0.003)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
export default BedIcon;
