import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    cursor: "pointer",
  },
});

export default function Clear(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root} onClick={props.onClick}>
      Limpiar
    </div>
  );
}
