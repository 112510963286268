import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from "antd";
import clsx from "clsx";

const useStyles = makeStyles({
  root: (props) => ({
    height: 50,
    marginBottom: 10,
    marginTop: 10,
    minWidth: 220,
    "&::placeholder": {
      color: "black",
    },
  }),
});

export default function InputField(props) {
  const classes = useStyles(props);

  return <Input {...props} className={clsx(classes.root, props.className)} />;
}
