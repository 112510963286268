import React from "react";
import "antd/dist/antd.css";
import { Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  root: (props) => ({
    marginRight: props.home ? 0 : 4,
    width: "100%",
    "& > button": {
      color: props.home
        ? "#0D3B92 !important"
        : props.focus
        ? "#0D3B92"
        : "#C0CCDB",
      fontWeight: props.focus ? "bold" : "normal",
      borderWidth: props.home ? 0 : 2,
      borderColor: props.focus ? "#0D3B92" : "#C0CCDB",
      borderRadius: props.home ? 0 : 10,
    },
    "& > :hover": {
      color: "#0D3B92 !important",
      borderColor: "#0D3B92 !important",
    },
  }),
  button: (props, md) => ({
    width: "100%",
    height: "100% !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: props.home ? 26 : 16,
  }),
});

export default function Dropdownn(props) {
  const md = useMediaQuery("(min-width:1030px)");
  const classes = useStyles(props, md);

  return (
    <div className={classes.root}>
      <Dropdown
        overlay={props.children}
        trigger={["click"]}
        style={{ backgroundColor: "Red" }}
      >
        <Button onClick={(e) => e.preventDefault} className={classes.button}>
          {props.description}
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
}
