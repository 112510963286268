// Import Swiper React components
import { Card } from "../index";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect } from "react";
import Slider from "react-slick";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@material-ui/core/styles";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { colorSecundary } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .slick-dots": {
      bottom: 0,
    },
    "& .slick-dots li": {
      width: 2,
      height: 2,
    },
    "& .slick-active .s-active": {
      color: colorSecundary,
    },
    "& .item": {
      color: "white",
      fontSize: 6,
    },
  },
}));

export default (props) => {
  const md = useMediaQuery("(min-width:1030px)");
  const sm = useMediaQuery("(min-width:600px)");

  const classes = useStyles();

  const settings = {
    dots: props.dots,
    infinite: true,
    slidesToShow: sm ? 4 : 1,
    slidesToScroll: 1,
    customPaging: function (i) {
      return (
        <div style={{ bottom: 10 }}>
          <a>
            <RadioButtonCheckedIcon className="item s-active" />
          </a>
        </div>
      );
    },
    nextArrow: props.arrows ? (
      <div>
        <ArrowForwardIosIcon style={{ color: "blue", fontSize: "20px" }} />
      </div>
    ) : (
      <></>
    ),
    prevArrow: props.arrows ? (
      <div>
        <ArrowBackIosIcon style={{ color: "blue", fontSize: "20px" }} />
      </div>
    ) : (
      <></>
    ),
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <Slider
        {...settings}
        style={{ backgroundColor: !sm && props.dots && "#EFF4FF" }}
      >
        {props.cards
          ? props.properties.map((it, key) => (
              <div key={key}>
                <div
                  onClick={() =>
                    (window.location = `/detalle/?code=${it.code}&category_id=${props.category_id}`)
                  }
                >
                  <div style={{ marginRight: 5, marginLeft: 5 }}>
                    <Card
                      key={it.code}
                      property={it}
                      category_id={props.category_id}
                    ></Card>
                  </div>
                </div>
              </div>
            ))
          : props.images.map((it, key) => (
              <div
                onClick={() => {
                  props.setViewer(true);
                  props.setActiveIndex(key);
                }}
              >
                <img
                  src={`${it.source}`}
                  style={{
                    maxHeight: 200,
                    objectPosition: "center",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </div>
            ))}
      </Slider>
    </div>
  );
};
