import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "../../components/index";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  save,
  getFavoritesCodes,
  deleteItem,
  getFavorites,
} from "../../utils/local-storage";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  contendProperties: (props) => ({
    display: "grid",
    gridTemplateColumns: props.md
      ? "repeat(4, 1fr)"
      : props.sm
      ? "repeat(4, 1fr)"
      : "repeat(1, 1fr)",
    gridGap: theme.spacing(2),
    gridAutoRows: "minmax(100px, auto)",
  }),
}));

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function Favorites(props) {
  const md = useMediaQuery("(min-width:1030px)");
  const sm = useMediaQuery("(min-width:600px)");
  const [properties, setProperties] = useState([]);
  const classes = useStyles({ md, sm });
  const [favoritesCodes, setFavoritesCodes] = useState([]);
  const [favorites, setFavorites] = useState({});

  const setFavorite = (index, props) => {
    save(index, { ...props.property });
  };

  const deleteFavorite = (index, code) => {
    deleteItem(index, code);
  };

  useEffect(
    debounce(() => {
      getFavoritesCodes("favorites").then((it) => setFavoritesCodes(it));
    }, 250),
    [favoritesCodes]
  );

  const getF = () => {
    const prope = [];
    getFavorites("favorites").then((it) => {
      const f = JSON.parse(it);
      Object.keys(JSON.parse(it)).forEach((element) => {
        const data = f[element];

        prope.push(data);
      });
      setProperties(prope);
    });
  };

  useEffect(() => {
    getF();
  }, [favoritesCodes]);

  return (
    <div className={classes.container}>
      <div className={classes.contendProperties}>
        {properties.length > 0 &&
          properties.map((it) => (
            <Card
              deleteItemFavorite={deleteFavorite}
              saveFavorite={setFavorite}
              favorite={favoritesCodes.includes(it.code)}
              key={it.code}
              code={it.code}
              property={it}
              category_id={props.category_id}
              hiddenPrice={true}
            ></Card>
          ))}
        {properties.length == 0 && (
          <div>
            <div>No se encontrarón resultados</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Favorites;
