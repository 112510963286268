import React, { useEffect, useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";

export default (props) => {
  useEffect(() => {}, []);

  return (
    <div>
      {props.visible && (
        <ImageViewer
          src={props.images.map((it) => it.src)}
          currentIndex={props.activeIndex}
          onClose={() => props.onClose()}
          backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.8)" }}
        />
      )}
    </div>
  );
};
