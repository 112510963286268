import React from "react";
import ReactDOM from "react-dom";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { useState } from "react";
import { googleMapCredentials } from "../../constant/index";
import { Card } from "./../index";

export function MapContainer(props) {
  const [selectPlace, setSelectPlace] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});

  const onMarkerClick = (props, marker) => {
    setSelectPlace(props);
    setActiveMarker(marker);
    setShowInfo(true);
    console.log(props, "propspropspropspropsprops");
  };

  const onInfoWindowOpen = (props, e) => {
    const button = (
      <div
        onClick={() =>
          (window.location = `/detalle/?code=${selectPlace.code}&category_id=${props.category_id}`)
        }
      >
        <Card
          property={selectPlace}
          modalMap={props.modalMap}
          category_id={props.category_id}
        />
      </div>
    );
    ReactDOM.render(
      React.Children.only(button),
      document.getElementById("url")
    );
  };

  return (
    <div>
      <Map
        google={props.google}
        zoom={12}
        initialCenter={{
          lat: props.unit ? props.markerMap[0]?.lat || 10.96 : 10.96,
          lng: props.unit ? props.markerMap[0]?.lng || -74.83 : -74.83,
        }}
        style={{ maxWidth: 480 }}
      >
        {props.markerMap.length &&
          props.markerMap.map((it) => (
            <Marker
              key={it.code}
              code={it.code}
              id={it.code}
              title={`${it.name}`}
              name={`${it.name}`}
              address={it.address}
              city={it.city}
              area={it.area}
              bathrooms={it.bathrooms}
              bedroom={it.bedroom}
              price={it.price}
              mainImageURL={it.img}
              position={{ lat: it.lat, lng: it.lng }}
              icon={{
                url:
                  "https://bienvivir.com.co/wp-content/uploads/2021/02/iconMap.svg",
                // anchor: new props.google.maps.Point(0, 0),
                scaledSize: new props.google.maps.Size(40, 40),
              }}
              totalMonthlyFee={it.totalMonthlyFee}
              price={it.price}
              unitCategoryDescription={it.unitCategoryDescription}
              onClick={props.modalMap ? onMarkerClick : () => {}}
            />
          ))}
        <InfoWindow
          visible={showInfo}
          marker={activeMarker}
          onOpen={(e) => {
            onInfoWindowOpen(props, e);
          }}
        >
          <div id="url" />
        </InfoWindow>
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: googleMapCredentials.apiKey,
})(MapContainer);
