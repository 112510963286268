import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "antd";
import { WhatsAppIcon, CallIcon } from "../icons/index";
import { Formik, Field } from "formik";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { projectId } from "../../constant/index";
import Axios from "../../api/axios";
import moment from "moment";
import { Snackbar } from "../index";

import {
  InputField,
  DateInput,
  TimePicker,
  InputTextArea,
  ButtonMe,
} from "../index";

const useStyles = makeStyles({
  root: (props) => ({}),
  contendForm: {
    maxWidth: 500,
    border: "1px #B3CAE3 solid",
    borderRadius: 20,
  },
  contendForm2: (props) => ({
    borderRadius: 20,
    width: !props.sm ? "100%" : !props.md ? "300px" : "500px",
    paddingRight: 20,
    paddingLeft: 20,
  }),
  title: {
    marginTop: 20,
    marginBottom: 20,
    fontWeight: "bold",
    fontSize: 22,
  },
  contendInputGroup: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  inputsError: {
    borderColor: "red",
  },
  showError: {
    color: "red",
    textAlign: "left",
  },
});

export default function ContactForm(props) {
  const md = useMediaQuery("(min-width:1030px)");
  const sm = useMediaQuery("(min-width:600px)");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [submiting, setSubmitting] = useState(false);
  const classes = useStyles({ props, sm, md });
  const axios = new Axios();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.contendForm}>
        <div className={classes.contendForm2}>
          <div>
            <div className={classes.title}>Contacto</div>
            <Formik
              enableReinitialize
              initialValues={{
                fullname: "",
                lastname: "",
                email: "",
                date: "",
                hour: "",
                phone: "",
                terms: false,
                message: `Deseo tener mas información sobre el proyecto ${props.projectName}.`,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "El correo es requerido.";
                } else if (
                  values.email &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Correo inválido.";
                }
                if (!values.fullname) {
                  errors.fullname = "El nombre es requerido.";
                }
                if (!values.lastname) {
                  errors.lastname = "El apellido es requerido.";
                }
                if (!values.phone) {
                  errors.phone = "El telefono es requerido.";
                }
                if (!values.terms) {
                  errors.terms = "Debes aceptar los términos y condiciones";
                }
                return errors;
              }}
              onSubmit={(values, { setFieldValue, resetForm }) => {
                setSubmitting(true);
                const data = {
                  projectId: projectId,
                  name: `${values.fullname} ${values.lastname}`,
                  mobile_number: values.phone,
                  comment: `date: ${moment(values.date).format(
                    "YYYY-MM-DD"
                  )} hour:${moment(values.hour).format("HH:mm")} comment:${
                    values.message
                  }`,
                  modudeId: props.moduleId,
                  origin: "Web",
                  source: "Web",
                  locationSourceId: "f5d0f84d-5ef0-485b-8c5f-25b4133bcaeb",
                  email: values.email,
                };
                axios
                  .createContact(data)
                  .then((it) => {
                    setSubmitting(false);
                    resetForm({});
                    setOpenSnackbar(true);
                    setMessage("Datos enviados.");
                    setSnackbarType("success");
                  })
                  .catch((e) => {
                    setSubmitting(false);
                    setOpenSnackbar(true);
                    setMessage("Error enviando datos.");
                    setSnackbarType("error");
                  });
              }}
            >
              {({
                props,
                values,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className={classes.showError}>
                      {errors.fullname && touched.fullname && errors.fullname}
                    </div>
                    <InputField
                      placeholder="Nombres *"
                      name="fullname"
                      className={errors.fullname && classes.inputsError}
                      onChange={(e) =>
                        setFieldValue(e.target.name, e.target.value)
                      }
                      onBlur={handleBlur}
                      value={values.fullname}
                    />
                  </div>
                  <div>
                    <div className={classes.showError}>
                      {errors.lastname && touched.lastname && errors.lastname}
                    </div>
                    <InputField
                      placeholder="Apellidos *"
                      className={errors.lastname && classes.inputsError}
                      name="lastname"
                      onChange={(e) =>
                        setFieldValue(e.target.name, e.target.value)
                      }
                      onBlur={handleBlur}
                      value={values.lastname}
                    />
                  </div>
                  <div>
                    <div className={classes.showError}>
                      {errors.phone && touched.phone && errors.phone}
                    </div>
                    <InputField
                      placeholder="Telefono *"
                      className={errors.phone && classes.inputsError}
                      name="phone"
                      onChange={(e) =>
                        setFieldValue(e.target.name, e.target.value)
                      }
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                  </div>
                  <div>
                    <div className={classes.showError}>
                      {errors.email && touched.email && errors.email}
                    </div>
                    <InputField
                      placeholder="Correo Electrónico *"
                      className={errors.email && classes.inputsError}
                      type="email"
                      name="email"
                      onChange={(e) =>
                        setFieldValue(e.target.name, e.target.value)
                      }
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>
                  <div className={classes.contendInputGroup}>
                    <div>
                      <DateInput
                        placeholder="Fecha de preferencia"
                        name="date"
                        onChange={(e) => setFieldValue("date", e)}
                        onBlur={handleBlur}
                        value={values.date}
                      />
                    </div>
                    <div>
                      <TimePicker
                        name="hour"
                        onChange={(e) => setFieldValue("hour", e)}
                        onBlur={handleBlur}
                        value={values.hour}
                        placeholder="Hora de preferencia"
                      />
                    </div>
                  </div>
                  <div>
                    <div className={classes.showError}>
                      {errors.message && touched.message && errors.message}
                    </div>
                    <InputTextArea
                      onChange={(e) =>
                        setFieldValue(e.target.name, e.target.value)
                      }
                      onBlur={handleBlur}
                      value={values.message}
                      placeholder="Cuéntanos aquí todo lo que quieres saber"
                      rows={6}
                    />
                  </div>
                  <div
                    className={classes.showError}
                    style={{ marginBottom: 16 }}
                  >
                    {errors.terms && touched.terms && errors.terms}
                  </div>
                  <div className={classes.contendInputGroup}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <Checkbox
                        style={{
                          marginRight: 8,
                        }}
                        checked={values.terms}
                        onChange={(e) =>
                          setFieldValue("terms", e.target.checked)
                        }
                      />
                      <p
                        style={{
                          textAlign: "left",
                          maxWidth: 320,
                          flexWrap: "wrap",
                          flexWrap: 1,
                        }}
                      >
                        Acepto los términos y políticas de privacidad para el
                        tratamiento de mis datos
                      </p>
                    </div>
                  </div>
                  <div className={classes.contendInputGroup}>
                    <ButtonMe
                      description={"Agendar cita"}
                      disabled={submiting}
                      type="submit"
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>

          <div className={classes.contendInputGroup}></div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 20,
          }}
        >
          <div
            style={{
              borderTop: "1px #B3CAE3 solid",
              borderRight: "1px #B3CAE3 solid",
              width: "100%",
              height: 45,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            <a
              href="https://api.whatsapp.com/send/?phone=%2B573216980171&text&app_absent=0"
              target="_blank"
            >
              <div style={{ display: "flex" }}>
                <WhatsAppIcon />
                &nbsp; Whatsapp
              </div>
            </a>
          </div>
          <div
            style={{
              borderTop: "1px #B3CAE3 solid",
              width: "100%",
              height: 45,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            <a href="tel:+573216980171" target="_blank">
              <div style={{ display: "flex" }}>
                <CallIcon /> &nbsp; llamanos
              </div>
            </a>
          </div>
        </div>
        <Snackbar
          open={openSnackbar}
          message={message}
          snackbarType={snackbarType}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
}
